import React from "react";
import { Box } from "@mui/material";
import MainHeader from "../../components/main/MainHeader";

function Blog(){

    return (
        <Box display="grid">
            <nav>
                <MainHeader />
            </nav>
        </Box>
    )
}

export default Blog;