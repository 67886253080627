import React from "react";
import '../../assets/css/plexus.css'

function Install(){

    return (
        <section id="download" className="download section">
            <div className="container">
                <div className="columns">
                    <div className="column is-6 has-text-centered">
                        <h2>Plexus for Mobile</h2>
                    </div>
                    <div className="column is-6 has-text-centered">
                        <h2>Lookout for Mobile</h2>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-6 has-text-centered">
                        <div className="download-window columns">
                            <div className="column">
                                <div className="download-window-wrapper">
                                    {/**Add image here */}
                                </div>
                            </div>
                        </div>
                        <a className="button large-button" href="/install/plexus">
                            Android
                        </a>
                        <a className="button large-button" href="/install/plexus">
                            Huawei (Planned)
                        </a>
                        <a className="button large-button" href="/install/plexus">
                            iPhone (Planned)
                        </a>
                        <p>&nbsp;</p>
                        <article className="message">
                            <div className="message-body">
                            We are aware that Plexus hasn't been updated in a while. We are currently rebuilding our back-end and working on Lookout. We do plan on rebuilding Plexus within this year or next year(2023 or at latest 2024).
                            </div>
                        </article>
                        <p>&nbsp;</p>
                        <p>
                            Or if you want, visit <a href="plexus.dev/install/plexus">plexus.dev/install/plexus</a> from your mobile device for instant download.
                        </p>
                    </div>
                    <div className="column is-6 has-text-centered">
                        <div className="download-window columns">
                            <div className="column">
                                <div className="download-window-wrapper">
                                    {/**Add image here */}
                                </div>
                            </div>
                        </div>
                        <a className="button large-button" href="/install/lookout">
                            Android
                        </a>
                        <a className="button large-button" href="/install/lookout">
                            Huawei (Planned)
                        </a>
                        <a className="button large-button" href="/install/lookout">
                            iPhone (Planned)
                        </a>
                        <p>&nbsp;</p>
                        <article className="message">
                            <div className="message-body">
                            Lookout is currently in development but you can follow the progress <a href="github.com">here.</a>
                            </div>
                        </article>
                        <p>&nbsp;</p>
                        <p>
                            Or if you want, visit <a href="plexus.dev/install/lookout" style={{ fontSize: '1em', fontWeight: '400', lineHeight: '1.5' }}>plexus.dev/install/lookout</a> from your mobile device for instant download.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Install;