import { Box, Typography } from "@mui/material";
import React from "react";

function OpenSourcePolicyContent() {

    return (
        <Box className="section post">
            <article>
                <Box className="container is-centered">
                    <Box className="policy-post-header columns is-centered">
                        <Box className="column is-7">
                            <Typography
                                variant="h1"
                                fontSize="30px"
                                fontWeight="600"
                                lineHeight="40px"
                                textAlign='center'
                                fontFamily="BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                                Plexus Open Source Policy
                            </Typography>
                            <Typography
                                variant="body2"
                                fontSize="16px"
                                fontWeight="400"
                                lineHeight="22px"
                                textAlign='center'
                                fontFamily="BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                                Updated on 18th March 2022
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="policy-post-content columns is-centered">
                        <Box className="column is-7">
                            <Typography
                                variant="p"
                                fontFamily="BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                                Millions of people worldwide rely on Signal every day for secure and private communication. Some of these people change their phone number, but want to continue using Signal without registering for a new account. We now provide the ability to change the phone number on your Signal account, while retaining all of your chats, profile information, and groups.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </article>
        </Box>
    )
}

export default OpenSourcePolicyContent;