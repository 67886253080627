import React from "react";
import '../../assets/css/plexus.css'

function MainHeader() {

    const [isActive, setisActive] = React.useState(false)

    return (
        <div className="container">
            <div className="navbar-brand">
                <a href="/">
                    <img className="plexus-logo" src={require('../../assets/logo.png')} alt="Plexus and Lookout Logo"/>
                </a>
                <a  href="/#" onClick={() => {
                    setisActive(!isActive)
                }} role='button' className={`navbar-burger burger ${isActive ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false" data-target='plexusNavbar'>
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                </a>
            </div>

            <div id="plexusNavbar" className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
                <div className="navbar-end">
                    <a className="navbar-item get-plexus" href="/install">
                        Install
                    </a>
                    <a className="navbar-item" href="/support">
                        Support
                    </a>
                    <a className="navbar-item" href="https://plexusinc.medium.com">
                        Blog
                    </a>
                    <a className="navbar-item" href="https://github.com/plexusinc/Roadmap/projects/" target="_blank" rel="noreferrer">
                        Roadmap
                    </a>
                    <a className="navbar-item" href="https://twitter.com" target="_blank" rel="noreferrer">
                        <i className="icon icon-twitter"></i>
                    </a>
                    <a className="navbar-item" href="https://instagram.com" target="_blank" rel="noreferrer">
                        <i className="icon icon-instagram"></i>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default MainHeader;