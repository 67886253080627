import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/footer/Footer';
import LandingPage from './core/home/LandingPage';
import Install from './core/install/Install';
import Blog from './core/blog/Blog';
import OpenSourcePolicy from './core/policy/OpenSourcePolicy';
import PrivacyPolicy from './core/legal/privacy-policy';
import TermsOfService from './core/legal/tos';
import MainHeader from "./components/main/MainHeader";
import NotFoundPage from "./core/error/404"

function App() {
  return (
    <div className="App">
      <body className='index has-navbar-fixed-top'>
        <nav className='navbar plexus-navbar is-fixed-top' role="navigation" aria-label='main navigation'>
          <MainHeader />
        </nav>
        <main>
          <BrowserRouter>
            <Routes>
              <Route exact path='/' element={<LandingPage />} />

              <Route exact path='install' element={<Install />} />
              <Route exact path='blog' element={<Blog />} />
              <Route exact path='open-source' element={<OpenSourcePolicy />} />
              <Route exact path='legal/privacy-policy' element={<PrivacyPolicy />} />
              <Route exact path='legal/terms-and-conditions' element={<TermsOfService />} />

              <Route element={<NotFoundPage />} />
              <Route path='*' element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        </main>
        <footer className='footer'>
          <Footer />
        </footer>
      </body>
    </div>
  );
}

export default App;
