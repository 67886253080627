import React from "react";

function NotFoundPage() {
    return (
        <div class="mainbox">
            <div class="err">4</div>
            <i class="far">0</i>
            <div class="err2">4</div>
            <p class="msg">Maybe this page moved? Got deleted? Is hiding out in quarantine? Never existed in the first place?
                <p>Let's go <a href="/">home</a> and try from there.</p>
            </p>
        </div>
    )
}

export default NotFoundPage;