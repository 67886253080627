import React from "react";
import OpenSourcePolicyContent from "../../components/main/OpenSourcePolicyContent";

function OpenSourcePolicy(){

    return (
        <OpenSourcePolicyContent/>
    )
}

export default OpenSourcePolicy;