import React from "react";

function Footer() {
    return (
        <div className="container">
            <div className="columns">
                <div className="column is-two-fifths is-hidden-mobile">
                    <span className="copyright">© 2019–2022 Plexus, Inc</span>
                    <br />
                    <br />
                    For media inquiries, contact
                    <a href="mailto:support@plexus.dev"> support@plexus.dev</a>
                    <br/>
                    With &#128156; from &#127487;&#127462; and &#127475;&#127473;
                </div>
                <div className="column">
                    <strong>Company</strong>
                    <ul>
                        <li>
                            <a href="/legal/privacy-policy">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/legal/terms-and-conditions">Terms and Conditions</a>
                        </li>
                    </ul>
                </div>
                <div className="column">
                    <strong>Plexus</strong>
                    <ul>
                        <li>
                            <a href="/install/plexus">Android</a>
                        </li>
                        <li>
                            <a href="/install/plexus">Huawei</a>
                        </li>
                        <li>
                            <a href="/install/plexus">iPhone</a>
                        </li>
                    </ul>
                </div>
                <div className="column">
                    <strong>Lookout</strong>
                    <ul>
                        <li>
                            <a href="/install/lookout">Android</a>
                        </li>
                        <li>
                            <a href="/install/lookout">Huawei</a>
                        </li>
                        <li>
                            <a href="/install/lookout">iPhone</a>
                        </li>
                    </ul>
                </div>
                <div className="column">
                    <strong>Social</strong>
                    <ul>
                        <li>
                            <a href="https://github.com/plexusinc">Github</a>
                        </li>
                        <li>
                            <a href="https://twitter.com">Twitter</a>
                        </li>
                        <li>
                            <a href="https://instagram.com">Instagram</a>
                        </li>
                    </ul>
                </div>
                <div className="column is-two-fifths is-hidden-tablet">
                    <span className="copyright">© 2019–2022 Plexus, Inc</span>
                    <br />
                    <br />
                    For media inquiries, contact
                    <a href="mailto:support@plexus.dev"> support@plexus.dev</a>
                    <br/>
                    With &#128156; from &#127487;&#127462; and &#127475;&#127473;
                </div>
            </div>
        </div>
    )
}

export default Footer;