import React from "react";

function LandingPage() {
    return (
        <dev>
            <section className="hero-main hero">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-two-fifths">
                                <h1>Your safety is a priority!</h1>
                                <p className="body1">
                                    Lookout was developed to help fix the continues struggle in todays society
                                    where our people doesn't feel safe around anyone anymore. Lookout wants
                                    to help people feel safe by making them aware of those wrong doers.
                                </p>
                                <br />
                                <p>
                                    <a className="get-plexus button button-white" href="/install"> Get Lookout </a>
                                </p>
                            </div>

                            {/**Mobile Images need to be added */}
                            <div className="column mobile-screenshots">
                                <img className="screenshot1" src={require('../../assets/main/screenshot2.png')} alt="Screenshot 2"/>
                                <img className="screenshot2" src={require('../../assets/main/screenshot1.png')} alt="Screenshot 1"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="why-plexus section">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column has-text-centered">
                            <h2>Why use Lookout?</h2>
                            <p className="body1">Explore below to see why Lookout is a go to app for communities.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section features">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-half has-text-centered">
                            <div className="box">
                                <img src={require('../../assets/main/report.png')} alt="Report"/>
                                <h3>Report a Lookout</h3>
                                <p className="body2">
                                    You can report a variety of lookouts ranging from unsafe ride-hailing drivers, vehicles, scams and more; with new lookout types coming soon.
                                </p>
                            </div>
                        </div>
                        <div className="column is-half has-text-centered">
                            <div className="box">
                                <img src={require('../../assets/main/map.png')} alt="Map"/>
                                <h3>Interactive Map</h3>
                                <p className="body2">
                                    Step away from the timeline and get a bit of interactive style of timeline going. With the map feature you can quickly find lookouts without stressing to much of where it might have happened.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="columns is-centered">
                        <div className="column is-half has-text-centered">
                            <div className="box">
                                <img src={require('../../assets/main/notify.png')} alt="Notified"/>
                                <h3>Get Notified</h3>
                                <p className="body2">
                                    Get notified of new updates on reports you may have logged on Lookout, like last seen locations...
                                </p>
                            </div>
                        </div>
                        <div className="column is-half has-text-centered">
                            <div className="box">
                                <img src={require('../../assets/main/connected.png')} alt="Connected"/>
                                <h3>Stay Anonymous</h3>
                                <p className="body2">
                                    Comments make it easy to stay connected with reporters, but more importantly when you comment we keep you anonymous at all times.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </dev>
    )
}

export default LandingPage;